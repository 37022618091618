<div class="container-fluid">
  <div class="row navbar">
    <div class="col">
      <app-navbar></app-navbar>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
