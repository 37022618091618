<div class="row servicio">
  <div class="col titulo">
    <br>
    <h1>Servicios</h1>
    <hr class="red">
    <hr class="blue">
  </div>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center">
      <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-end justify-content-center mb-4">
        <div class="card shadow" style="width: 25rem;">
          <div class="inner">
            <img src="../../../assets/servicios/1.png" class="card-img-top imagen" [routerLink]="['/serv_estructuras']">
          </div>
          <div class="card-body text-left">
            <h3 class="card-title">ESTRUCTURAS METALICAS</h3>
            <p class="card-text">Nuestro principal servicio en la industria son las estructuras metálicas en acero,
              donde contamos con suficiente experiencia (más de 15 años), capacidad y calidad en el mercado de la
              construcción. Realizando trabajos de ingeniería, fabricación y montaje de las estructuras. Esto nos ha
              permitido posicionarnos en mercados objetivos y específicos entregándonos un valor poderoso frente a las
              necesidades de la industria actual. Y gracias a nuestras capacidades y logros, estamos generando una
              seguidilla de valores únicos y produciendo las soluciones necesarias que nos permitirán disminuir costos y
              plazos de ejecución en las obras.</p>
          </div>
          <a [routerLink]="['/serv_estructuras']" class="btn btn-secondary col-12 mt-3 align-items-end">Ver Mas...</a>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-center justify-content-center mb-4">
        <div class="card shadow" style="width: 25rem;">
          <div class="inner">
            <img src="../../../assets/servicios/2.png" class="card-img-top imagen" [routerLink]="['/serv_aceroinox']">
          </div>
          <div class="card-body text-left">
            <h3 class="card-title">ACERO INOXIDABLE</h3>
            <p class="card-text">Nuestra empresa también esta dedicada a la fabricación y montaje de equipamiento de
              acero inoxidable, buscando la satisfacción de las necesidades del cliente en la industria de la
              construcción. Como expertos en el área, estamos comprometidos a desarrollar su proyecto, cumpliendo a
              cabalidad con la legislación y reglamentación vigente actual, sistema de gestión de calidad, mejora
              continua de procesos (fabricación e instalación), compromiso de todo el personal, para así, lograr y hacer
              cumplir la misión principal de la empresa, basada en 15 años de experiencia en el rubro.</p>
          </div>
          <a [routerLink]="['/serv_aceroinox']" class="btn btn-secondary col-12 mt-3 align-items-end">Ver Mas...</a>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-4 d-flex mt-3 d-flex justify-content-xl-start justify-content-center mb-4">
        <div class="card shadow" style="width: 25rem; ">
          <div class="inner">
            <img src="../../../assets/servicios/3.png" class="card-img-top imagen" [routerLink]="['/serv_arriendos']">
          </div>
          <div class="card-body text-left">
            <h3 class="card-title">SERVICIOS DE ARRIENDO</h3>
            <p class="card-text">Ofrecemos a nuestros clientes el mejor servicio de arriendo de maquinarias, incluyendo
              camión pluma, camión plano y miniexcavadora. Con nuestras soluciones, podrá mejorar la eficiencia en su
              trabajo sin necesidad de una inversión adicional de capital ni enfrentar gastos de mantenimiento. Nuestras
              máquinas y equipos están siempre en óptimas condiciones, y nuestros especialistas están disponibles y
              listos para responder a sus solicitudes.
            </p>
          </div>
          <a [routerLink]="['/serv_arriendos']" class="btn btn-secondary col-12 mt-3 align-items-end">Ver Mas...</a>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-end justify-content-center mb-4">
        <div class="card shadow" style="width: 25rem; ">
          <div class="inner">
            <img src="../../../assets/servicios/4.png" class="card-img-top imagen"
              href="https://www.camarasdesgrasadoras.cl/">
          </div>
          <div class="card-body text-left">
            <h3 class="card-title">CAMARAS DESGRASADORAS</h3>
            <p class="card-text">Los separadores de grasas o camaras desgrasadoras o como tambien se les conocen camara
              atrapa grasas son una de nuestra especialidades, fabricando una versión standard(modelo SEGRAS) y otra a
              pedidos del consumidor (personalizadas). Siendo estas hechas de acero inoxidable, cumpliendo con las
              normas exigidas por la SISS, DS609/98, donde se indica la regulación de los limites máximos de aceites y
              grasas permitidos para ser vertidos en los desagües sanitarios. Evitando así la obstrucción o corrosión en
              los sistemas de tuberías y alcantarillados. Utilizando este método, usted esta ayudando al medioambiente,
              evitando así la contaminación de las aguas. Fabricados en acero inoxidable calidad 304L.
            </p>
          </div>
          <a href="https://www.camarasdesgrasadoras.cl/" class="btn btn-secondary col-12 mt-3 align-items-end">Ir a la
            web...</a>
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-center justify-content-center mb-4">
        <div class="card shadow" style="width: 25rem; ">
          <div class="inner">
            <img src="../../../assets/servicios/5.png" class="card-img-top imagen" [routerLink]="['/serv_pinturas']">
          </div>
          <div class="card-body text-left">
            <h3 class="card-title">PINTURA ELECTROESTATICA</h3>
            <p class="card-text">La pintura en polvo puede satisfacer en su producto una amplia variedad de apariencias
              y características de acuerdo a su función en específico. Esto incluiría el espesor de la película, el
              brillo, la textura, el color y la calidad (dureza, resistencia química, resistencia a los rayos
              ultravioleta, etc.) del recubrimiento. Cada uno de estos tipos de pintura es utilizado en la industria
              para diferentes aplicaciones, en las cuales se deben tener en cuenta factores tales como: la corrosión, la
              exposición al ambiente, la resistencia al impacto, por mencionar algunos.
            </p>
          </div>
          <a [routerLink]="['/serv_pinturas']" class="btn btn-secondary col-12 mt-3 align-items-end">Ver Mas...</a>
        </div>
      </div>
    </div>
  </div>
</div>