<div class="row servicio" id="inicio_serv_pinturas">
    <div class="col-12 titulo">
        <br>
        <h1>Pintura Electrostática</h1>
        <hr class="red">
        <hr class="blue">
    </div>
    <div class="row -flex justify-content-center">
        <div class="col-md-9">
        </div>
    </div>
</div>

<div class="col-12 intro animate__animated animate__fadeInLeft" *ngIf="imagesLoaded">
    <div class="col-8 intro2">
        <i class="fas fa-thumbs-up fa-2x mb-3 mt-3"></i>
        <strong>Ventajas:</strong>
        <ul>
            <li><b>ESTETICA: </b>El recubrimiento es uniforme y el color se mantiene en el tiempo.</li>
            <li><b>SEGURIDAD: </b> No inflamable, sin compuestos orgánicos volátiles ni solventes (sin riesgos para la
                salud).</li>
            <li><b>DURABILIDAD: </b> Resistente a corrosión, agentes químicos, rayos UV, rayaduras y golpes.</li>
            <li><b>COSTOS OPERATIVOS: </b> Cubre más área, menos desperdicio, y solo 30 minutos de enfriamiento.</li>
            <li><b>MEDIO AMBIENTE: </b> Libre de compuestos orgánicos volátiles y solventes. El exceso de pintura es
                recuperable y reutilizable.</li>
        </ul>
    </div>
</div>

<div class="col-12">
    <h3>Proyectos Ejecutados</h3>
</div>

<div *ngIf="!imagesLoaded">
</div>

<div *ngIf="imagesLoaded" class="row row_galeria">
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/1.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/1.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/2.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/2.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/3.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/3.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/4.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/4.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/5.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/5.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/6.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/6.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/7.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/7.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/8.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/8.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/9.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/9.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/10.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/10.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/11.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/11.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/12.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/12.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/13.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/13.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/14.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/14.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/15.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/15.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/16.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/16.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/17.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/17.jpg" alt="">
            </div>
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-12">
        <a href="../../../assets/pintura/18.jpg" data-lightbox="carrusel">
            <div class="img-container">
                <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                    src="../../../assets/pintura/18.jpg" alt="">
            </div>
        </a>
    </div>
</div>