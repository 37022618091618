import { Component, OnInit } from '@angular/core';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-servicios-sanitarios',
  templateUrl: './serv-estructuras.component.html',
  styleUrls: ['./serv-estructuras.component.css']
})
export class ServEstructurasComponent implements OnInit {

  imagesLoaded = false;

  constructor() { }

  ngOnInit(): void {
    this.cargarImagenes();
  }

  cargarImagenes() {
    Notiflix.Loading.dots('Cargando...', {
      backgroundColor: 'rgba(200, 200, 200, 0.9)', // Fondo en color plomo claro con transparencia de 0.8
      svgColor: '#fafcfb'
    }); // Muestra el mensaje de "Cargando..."

    // Simula el tiempo de carga de las imágenes (ajusta según tus necesidades)
    setTimeout(() => {
      Notiflix.Loading.remove(); // Oculta el mensaje de "Cargando..."
      this.imagesLoaded = true;
    }, 1000);
  }

}
