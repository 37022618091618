<div class="row servicio" id="inicio_serv_aceroinox">
    <div class="col-12 titulo">
        <br>
        <h1>Acero Inoxidable</h1>
        <hr class="red">
        <hr class="blue">
    </div>
    <div class="row -flex justify-content-center">
        <div class="col-md-9">
        </div>
    </div>
</div>

<div class="row" class="w-100">
    <div class="row intro animate__animated animate__fadeInLeft" *ngIf="imagesLoaded">
        <div class="col-lg-6 col-md-4 col-sm-12 mt-3 intro2">
            <i class="fas fa-tools fa-2x mb-3 mt-3"></i>
            <strong>Contamos Con:</strong>
            <ul>
                <li>Taller o Maestranza.</li>
                <li>Equipo Especializado.</li>
                <li>Herramientas y Equipos.</li>
                <li>Transporte y Maquinaria.</li>
            </ul>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-12 mt-3 intro2">
            <i class="fas fa-tasks fa-2x mb-3 mt-3"></i>
            <strong>Logrando:</strong>
            <ul>
                <li>Ingenieria al Detalle.</li>
                <li>Asistencia en Diseño.</li>
                <li>Fabricacion de Estructuras.</li>
                <li>Montaje Especializado.</li>
                <li>Calidad.</li>
            </ul>
        </div>
    </div>
    <div class="col-12">
        <h3>Proyectos Ejecutados</h3>
    </div>

    <div *ngIf="!imagesLoaded">
    </div>


    <div *ngIf="imagesLoaded" class="row row_galeria">
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/1.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/1.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/2.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/2.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/3.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/3.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/4.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/4.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/5.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/5.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/6.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/6.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/7.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/7.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/8.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/8.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/9.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/9.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/10.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/10.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/11.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/11.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/12.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/12.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/13.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/13.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/14.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/14.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/15.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/15.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/16.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/16.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/17.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/17.png" alt="">
                </div>
            </a>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12">
            <a href="../../../assets/galeria_acero/18.png" data-lightbox="carrusel">
                <div class="img-container">
                    <img class="img w-100 mb-4 rounded animate__animated animate__fadeInLeft"
                        src="../../../assets/galeria_acero/18.png" alt="">
                </div>
            </a>
        </div>
    </div>