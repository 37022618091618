import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.handleNavigationScroll();
  }

  handleNavigationScroll(): void {
    // Escuchar eventos de navegación para manejar el scroll
    this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const fragment = this.getFragmentFromUrl(event.url);
      if (fragment) {
        this.scrollToSection(fragment);
      }
    });
  }

  private getFragmentFromUrl(url: string): string | null {
    const fragmentIndex = url.indexOf('#');
    return fragmentIndex !== -1 ? url.substring(fragmentIndex + 1) : null;
  }

  scrollToSection(section: string): void {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // Whatsapp
  phoneNumber = '56972360092'; // Ejemplo: '1234567890'
  whatsappUrl = `https://api.whatsapp.com/send?phone=${this.phoneNumber}`;

  openWhatsApp(): void {
    window.open(this.whatsappUrl, '_blank');
  }

}
