<div class="row clientes">
  <div class="col titulo">
    <br>
    <h1>Principales Clientes</h1>
    <hr class="red">
    <hr class="blue">
  </div>
</div>
<div class="row mb-4">
  <div class="col divcol">
    <img src="../../../assets/clientes/metro.png" class="img-thumbnail rounded imgclientes" alt="">
    <img src="../../../assets/clientes/Arrigoni.png" class="img-thumbnail rounded imgclientes" alt="">
    <img src="../../../assets/clientes//Besalco.png" class="img-thumbnail rounded imgclientes" alt="">
    <img src="../../../assets/clientes/OHL.png" class="img-thumbnail rounded imgclientes" alt="">
    <img src="../../../assets/clientes/Tecsa.png" class="img-thumbnail rounded imgclientes" alt="">
    <img src="../../../assets/clientes/Efe.png" class="img-thumbnail rounded imgclientes" alt="">
  </div>
</div>
