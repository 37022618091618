<div class="row servicio" id="inicio_serv_arriendos">
  <div class="col-12 titulo">
    <br>
    <h1>Arriendos</h1>
    <hr class="red">
    <hr class="blue">
  </div>
  <div class="row -flex justify-content-center">
    <div class="col-md-9">
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col">

    <div *ngIf="!imagesLoaded">
    </div>

    <div *ngIf="imagesLoaded" class="container-fluid mb-5">
      <div class="row d-flex justify-content-center">
        <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-center justify-content-center">
          <div class="card shadow animate__animated animate__fadeInLeft" style="width: 25rem;">
            <div class="inner">
              <img src="../../../assets/Arriendos/5.png" class="card-img-top imagen" alt="...">
            </div>
            <div class="card-body text-left">
              <h3 class="card-title mb-3" style="text-align: center;">Miniexcavadora</h3>
              <ul>
                <li class="mb-2"><strong>Uso: </strong>Excavación, Movimiento de Tierra y Demolición.</li>
                <li class="mb-2"><strong>Accesorios: </strong>Balde Frontal, Balde en Aguilón y Martillo Demoledor.</li>
                <li class="mb-2"><strong>Profundidad de la excavacion: </strong>3 metros.</li>
                <li class="mb-2"><strong>Altura y Largo del Brazo: </strong>4 metros.</li>
                <li class="mb-2"><strong>Transporte: </strong>En camión plano.</li>
              </ul>
            </div>
            <a (click)="Ircontacto()" class="btn btn-secondary col-12 mt-2 align-items-left">Contactanos</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4 mt-3 d-flex justify-content-xl-center justify-content-center">
          <div class="card shadow animate__animated animate__fadeInLeft" style="width: 25rem;">
            <div class="inner">
              <img src="../../../assets/Arriendos/4.png" class="card-img-top imagen" alt="...">
            </div>
            <div class="card-body text-left">
              <h3 class="card-title" style="text-align: center;">Camion Pluma</h3>
              <ul>
                <li class="mb-2"><strong>Uso: </strong>Excavación, Movimiento de Tierra y Demolición.</li>
                <li class="mb-2"><strong>Capacidad de levante (Pluma): </strong>2,5 toneladas máx (2.500 kilos) al lado
                  del camión.</li>
                <li class="mb-2"><strong>Largo (Pluma): </strong>9 metros max.</li>
                <li class="mb-2"><strong>Capacidad de carga (Rampla): </strong>5 toneladas.</li>
                <li class="mb-2"><strong>Dimension (Rampla): </strong>2,2 x 6 metros</li>
              </ul>
            </div>
            <a (click)="Ircontacto()" class="btn btn-secondary col-12 mt-2 align-items-end">Contactanos</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>