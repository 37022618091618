<div class="fixed-top">
    <ul class="top_bar">
      <li><i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;ventas@victher.cl</li>
      <li><i class="fa-solid fa-phone"></i>&nbsp;&nbsp;+56926347464</li>
      <li><i class="fab fa-lg fa-whatsapp whatsapp" (click)="openWhatsApp()"></i></li>
    </ul>
  <nav class="navbar navbar-expand-lg navbar-light d-flex">
    <a [routerLink]="['/home']">
      <img src="../../../assets/Logo_victher.png" class="" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link link" id="inicio2" [routerLink]= "['/inicio']" fragment="inicio">INICIO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link link" id="servicios2" [routerLink]= "['/servicios']" fragment="servicios">SERVICIOS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link link" id="clientes2" [routerLink]= "['/clientes']" fragment="clientes">CLIENTES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link link" id="nosotros2" [routerLink]= "['/nosotros']" fragment="nosotros">NOSOTROS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link link" id="contacto2" [routerLink]= "['/contacto']" fragment="contacto">CONTACTO</a>
        </li>
      </ul>
    </div>
  </nav>
</div>
