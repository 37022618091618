import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-serv-arriendos',
  templateUrl: './serv-arriendos.component.html',
  styleUrls: ['./serv-arriendos.component.css']
})
export class ServArriendosComponent implements OnInit {

  imagesLoaded = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.cargarImagenes();
  }

  // Ir al Contacto

  funcionCinco(){
    this.router.navigateByUrl('/home#contacto');
  }

  Ircontacto(){
    this.funcionCinco();

    setTimeout(() => {
      const element = document.getElementById('contacto');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }

// Cargar imagenes
cargarImagenes() {
  Notiflix.Loading.dots('Cargando...', {
    backgroundColor: 'rgba(200, 200, 200, 0.9)', // Fondo en color plomo claro con transparencia de 0.8
    svgColor: '#fafcfb'
  }); // Muestra el mensaje de "Cargando..."

  // Simula el tiempo de carga de las imágenes (ajusta según tus necesidades)
  setTimeout(() => {
    Notiflix.Loading.remove(); // Oculta el mensaje de "Cargando..."
    this.imagesLoaded = true;
  }, 1000);
}



}
