import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as Notiflix from 'notiflix';
import { CorreoService } from 'src/app/servicios/correo.service';
import { ReCaptchaV3Service  } from 'ng-recaptcha';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  forma!: UntypedFormGroup;

  constructor( private fb: UntypedFormBuilder, private _CorreoService: CorreoService, private recaptchaV3Service: ReCaptchaV3Service  ) {

    this.crearFormulario();

  }

  ngOnInit(): void {
  }

  get nombreNoValido(){
    return this.forma.get('nombre')?.invalid && this.forma.get('nombre')?.touched;
  }

  get emailNoValido(){
    return this.forma.get('email')?.invalid && this.forma.get('email')?.touched;
  }

  get telefonoNoValido(){
    return this.forma.get('telefono')?.invalid && this.forma.get('telefono')?.touched;
  }

  get asuntoNoValido(){
    return this.forma.get('asunto')?.invalid && this.forma.get('asunto')?.touched;
  }

  get mensajeNoValido(){
    return this.forma.get('msg')?.invalid && this.forma.get('msg')?.touched;
  }


  enviar(){

    if (this.forma.invalid){
      return Object.values( this.forma.controls ).forEach( control => {
        control.markAsTouched();
      });
    }
    Notiflix.Loading.standard('cargando');

    this.recaptchaV3Service.execute('importantAction')
    .subscribe((token: string) => {
      console.debug(`Token [${token}] generated`);
      const recaptcha_response = token;

      // Rellenar Datos del formulario en un arreglo
      const datos = {
        nombre: this.forma.value.nombre,
        email: this.forma.value.email,
        telefono: this.forma.value.telefono,
        asunto: this.forma.value.asunto,
        mensaje: this.forma.value.msg,
        recaptcha: recaptcha_response
      }

      this._CorreoService.enviarCorreo(datos.nombre, datos.email, datos.telefono, datos.asunto, datos.mensaje, datos.recaptcha);
    });

    // Notiflix.Loading.remove();

  }

  crearFormulario(): void{

    this.forma = this.fb.group({

      nombre: ['', Validators.required],
      email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), Validators.required]],
      telefono: ['', Validators.required],
      asunto: ['Cotización', Validators.required],
      msg: ['',Validators.required]

    });

  }

}
