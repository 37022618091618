import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Notiflix from 'notiflix';

@Injectable({
  providedIn: 'root'
})
export class CorreoService {

  private apiUrl = 'https://victher.cl/cot.php';

  constructor(private http: HttpClient) { }


// Codigo correcto con PHP

enviarCorreo( nombre: string, email: string, telefono: string, asunto: string, mensaje: string, recaptcha: string){
  console.log('envio correo desde el servicio');

  const datos = new URLSearchParams();
  datos.set('nombre', nombre);
  datos.set('email', email);
  datos.set('telefono', telefono);
  datos.set('asunto', asunto);
  datos.set('mensaje', mensaje);
  datos.set('recaptcha', recaptcha);

  const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

  this.http.post(this.apiUrl, datos.toString(), {headers}).subscribe(resp => {
    console.log('Conexion ok con el backend');
    console.log(resp);
    Notiflix.Loading.remove();
    Notiflix.Notify.success('Correo enviado');
  }, error => {
    console.log('No se puede conectar con el back');
    console.log(error);
    Notiflix.Loading.remove();
    Notiflix.Notify.failure('Error 500');
  });
}

}
