import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  // Ir al serv_estructuras

  funcion() {
    this.router.navigateByUrl('/serv_estructuras');
  }

  Irserv_estructuras() {
    this.funcion();

    setTimeout(() => {
      const element = document.getElementById('inicio_serv_estructuras');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }
  // Ir al serv_aceroinox

  funcion1() {
    this.router.navigateByUrl('/serv_aceroinox');
  }

  Irserv_aceroinox() {
    this.funcion1();

    setTimeout(() => {
      const element = document.getElementById('inicio_serv_aceroinox');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }

  // Ir al serv_arriendos

  funcion3() {
    this.router.navigateByUrl('/serv_arriendos');
  }

  Irserv_arriendos() {
    this.funcion3();

    setTimeout(() => {
      const element = document.getElementById('inicio_serv_arriendos');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }

// Ir al serv_arriendos

funcion4() {
  this.router.navigateByUrl('/serv_pinturas');
}

Irserv_pinturas() {
  this.funcion4();

  setTimeout(() => {
    const element = document.getElementById('inicio_serv_pinturas');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 0);
}


}
