import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ServEstructurasComponent } from './components/serv-estructuras/serv-estructuras.component';
import { ServArriendosComponent } from './components/serv-arriendos/serv-arriendos.component';
import { ServAceroinoxComponent } from './components/serv-aceroinox/serv-aceroinox.component';
import { ServPinturaComponent } from './components/serv-pintura/serv-pintura.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  { path:'home', component: HomeComponent },
  { path:'serv_estructuras', component: ServEstructurasComponent },
  { path:'serv_arriendos', component: ServArriendosComponent },
  { path:'serv_aceroinox', component: ServAceroinoxComponent },
  { path:'serv_pinturas', component: ServPinturaComponent },
  {path: '**', redirectTo: 'home'}
];

export const AppRoutingModule = RouterModule.forRoot(routes);
