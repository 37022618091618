<div class="container-fluid">
  <div class="row" id="inicio">
    <div class="col">
      <app-carrusel></app-carrusel>
    </div>
  </div>
  <div class="row" id="servicios">
    <div class="col">
      <app-servicios></app-servicios>
    </div>
  </div>
  <div class="row" id="clientes">
    <div class="col">
      <app-clientes></app-clientes>
    </div>
  </div>
  <div class="row" id="nosotros">
    <div class="col">
      <app-nosotros></app-nosotros>
    </div>
  </div>
  <div class="row" id="contacto">
    <div class="col">
      <app-contacto></app-contacto>
    </div>
  </div>
  <div class="row footer">
    <div class="col text-center">
      <p>2023 © victher. Powered by VictherChile</p>
    </div>
  </div>
</div>

<!-- Ícono de WhatsApp flotante -->
<div class="whatsapp-container">
  <a class="whatsapp-icon" (click)="openWhatsApp()">
    <i class="fab fa-lg fa-whatsapp whatsapp"></i>
  </a>
</div>