<div class="row contacto">
  <div class="col titulo">
    <br>
    <h1>Contacto</h1>
    <hr class="red">
    <hr class="blue">
  </div>

  <div class="container-fluid mb-4">
    <div class="row mt-3 d-flex justify-content-center">
      <div class="col-12 col-md-4 col-sm-6 col-xl-3 mt-5 informacion-contacto">
        <h3>Informacion del contacto</h3>
        <hr>
        <p class="parrafo"><strong>Telefono:</strong> +56926347464</p>
        <p class="parrafo"><strong>Email:</strong> ventas@victher.cl</p>
        <p class="parrafo"><strong>Comuna:</strong> Padre Hurtado</p>
      </div>
      <div class="col-12 col-md-4 col-sm-6 col-xl-3 mt-5 email-contacto">
        <h3>Envianos tu proyecto</h3>
        <hr>
        <form [formGroup]='forma' (ngSubmit)="enviar()">
          <div class="mb-3">
            <label class="form-label">Nombre y Apellido</label>
            <input formControlName="nombre" type="email" class="form-control input-nombre text-ligth"
              aria-describedby="emailHelp" placeholder="&#xf007;  Ingresa tu nombre y Apellido"
              [class.is-invalid]="nombreNoValido">
            <small *ngIf="nombreNoValido" class="text-danger">
              El nombre es requerido
            </small>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input formControlName="email" type="email" class="form-control input-text" aria-describedby="emailHelp"
              placeholder="&#xf0e0;  Contacto@Victher.cl" [class.is-invalid]="emailNoValido">
            <small *ngIf="emailNoValido" class="text-danger">
              El email es requerido
            </small>
          </div>
          <div class="mb-3">
            <label class="form-label">Telefono</label>
            <input formControlName="telefono" type="text" class="form-control input-telefono"
              placeholder="&#xf2b9;  Ingresa tu telefono" maxlength="9" [class.is-invalid]="telefonoNoValido" oninput="this.value = this.value.replace(/[^0-9]/g, '');">
            <small *ngIf="telefonoNoValido" class="text-danger">
              El telefono es requerido
            </small>
          </div>
          <div class="mb-3">
            <label class="form-label">Asunto</label><br>
            <select formControlName="asunto" class="custom-select border-light checkbox"
              [class.is-invalid]="asuntoNoValido">
              <option selected value="Cotización">Cotización</option>
              <option value="Consulta">Consulta</option>
            </select>
            <small *ngIf="asuntoNoValido" class="text-danger">
              El Asunto es requerido
            </small>
          </div>
          <label for="floatingTextarea2" class="mb-2">Dejanos un mensaje...</label>
          <div class="form-floating">
            <textarea formControlName="msg" class="form-control textarea" placeholder="Leave a comment here"
              style="height: 120px; width: 100%;"></textarea>
            <small *ngIf="mensajeNoValido" class="text-danger">
              El Mensaje es requerido
            </small>
          </div>
          <button type="submit" class="btn btn-primary mt-2 col-12 boton"><i class="far fa-envelope"></i>
            Enviar</button>
        </form>
      </div>
    </div>
  </div>
</div>

<!--

  <hr>
  <pre>
    Estado Formulario: {{ forma.valid }}
    <br>
    Status: {{ forma.status }}
  </pre>

  <pre>
    {{ forma.value | json }}
  </pre>

-->

