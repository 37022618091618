import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit(): void {

  }

  // Whatsapp
  phoneNumber = '56926347464'; // Ejemplo: '1234567890'
  whatsappUrl = `https://api.whatsapp.com/send?phone=${this.phoneNumber}`;

  openWhatsApp(): void {
    window.open(this.whatsappUrl, '_blank');
  }

}