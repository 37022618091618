import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//Servicios
import { CorreoService } from './servicios/correo.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CarruselComponent } from './components/carrusel/carrusel.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ServEstructurasComponent } from './components/serv-estructuras/serv-estructuras.component';
import { HomeComponent } from './components/home/home.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { ServArriendosComponent } from './components/serv-arriendos/serv-arriendos.component';
import { ServAceroinoxComponent } from './components/serv-aceroinox/serv-aceroinox.component';
import { ServPinturaComponent } from './components/serv-pintura/serv-pintura.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarruselComponent,
    ServiciosComponent,
    ContactoComponent,
    ServEstructurasComponent,
    HomeComponent,
    NosotrosComponent,
    ClientesComponent,
    ServArriendosComponent,
    ServAceroinoxComponent,
    ServPinturaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module
  ],
  providers: [
    CorreoService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6Lfw0zEnAAAAAHbOEjEQmfGq94XfsPIVIxzTbAqw"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
