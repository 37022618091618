<div class="row contacto">
  <div class="col titulo">
    <br>
    <h1>Nosotros</h1>
    <hr class="red">
    <hr class="blue">
  </div>

<div clas="row row_contenedor">

  <div class="col-6 div1">
    <h3>Contamos con:</h3>
    <div class="row row1">
      <div class="col-lg-4 icono">
        <i class="fa-solid fa-industry fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Maestranza</h4>
      </div>
      <div class="col-lg-4 icono">
        <i class="fa-sharp fa-solid fa-people-line fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Equipos de trabajo</h4>
      </div>
      <div class="col-lg-4 icono">
        <i class="fa-solid fa-screwdriver-wrench fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Herramientas y Equipos</h4>
      </div>
    </div>
  </div>

  <div class="col-6 div2">
    <h3>Logrando:</h3>
    <div class="row row1">
      <div class="col-lg-4 icono">
        <i class="fa-solid fa-lightbulb fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Ingenieria al Detalle</h4>
      </div>
      <div class="col-lg-4 icono">
        <i class="fa-solid fa-pen-ruler fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Asistencia en Diseño</h4>
      </div>
      <div class="col-lg-4 icono">
        <i class="fa-solid fa-person-digging fa-3x mb-3 animate__animated animate__fadeInLeft" style="color:#545454"></i>
        <h4>Fabricacion de Estructuras</h4>
      </div>
    </div>
  </div>
</div>

  <div class="container-fluid mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-7">
        <p>Somos una empresa que enfoca sus esfuerzos en entregar el mejor servicio y la calidad en cada uno de los proyectos.
           Trabajamos con la calidad que necesitas. Calidad que es resultado de años de esfuerzo y dedicación por parte de nuestro equipo humano,
           buscamos seguir creciendo para poder ayudar a más personas y causar un efecto positivo en su diario vivir.</p>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-flex justify-content-center text-center">
      <div class="col-lg-5">
        <h3>Mision</h3>
        <hr>
        <p>Entregar soluciones a personas y empresas que requieran de una mejora en su calidad de vida, utilizando diferentes técnicas
           en acero y otras obras de ingeniería. Nos apasiona lo que hacemos y buscamos siempre la mejora continua en todos nuestros procesos.</p>
      </div>
      <div class="col-lg-5">
        <h3>Vision</h3>
        <hr>
        <p>Ser una de las empresas líderes en la elaboración de proyectos de obras civiles. Siendo un aporte a la mejora del medio ambiente
           entregando soluciones que colaboren con un cambio en la calidad de vida, a través de nuestros diferentes productos y servicios.</p>
      </div>
    </div>
  </div>
</div>
