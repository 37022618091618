<div id="carouselExampleCaptions" class="carousel slide mt-2 carrusel animated fadeIn carousel-fade" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../assets/Carrusel/carrusel1.png" class="d-block w-100 car" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="animate__animated animate__fadeInLeft mb-2">ACERO ESTRUCTURAL EN LA INDUSTRIA</h1>
        <P class="animate__animated animate__fadeInLeft">Soluciones Integrales para la Fabricación y Montaje frente a los Requerimientos de Infraestructura de Pequeños a Grandes Proyectos</P>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../assets/Carrusel/logo5.png" class="d-block w-100 car" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="animate__animated animate__fadeInLeft mb-2">ESTRUCTURAS DE ACERO INOXIDABLE</h1>
        <P class="animate__animated animate__fadeInLeft">Diseño, Fabricación e Instalación para Diversos Usos Industriales, Alimentación, Salud, Hogar y Oficina</P>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../../../assets/Carrusel/logo4.png" class="d-block w-100 car" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h1 class="animate__animated animate__fadeInLeft mb-2">ARRIENDO MAQUINARIAS</h1>
        <p class="animate__animated animate__fadeInLeft">Maquinarias Disponibles y Preparadas para Cumplir con las más Altas Exigencias Requeridas en su Obra</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
